import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { useSelector } from "react-redux";
import AccountInfo from "./AccountInfo";
import Scores from "./Scores";
import Orders from "./Orders";
import Address from "./Address";
import Photos from "./Photos";
import Messages from "./Messages";
import Favourites from "./Favourites";
import Performance from "./Performance";
import Dashboard from "./Dashboard";

// Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

const ProfileTest = () => {
  const location = useLocation();
  const profileDetail = useSelector((store) => store.profile.profileDetail);
  const profilePhoto = useSelector((store) => store.profile.profilePhoto);
  const [page, setPage] = React.useState("accountInfo");
  const [profilePhotoItem, setProfilePhotoItem] = useState(null);
  const [selectedItem, setSelectedItem] = useState("/profile/account-info");

  useEffect(() => {
    const currentPage = location.pathname.split("/")[2];
    setPage(currentPage);
    setSelectedItem(location.pathname);
  }, [location]);

  useEffect(() => {
    if (profilePhoto) {
      for (let i = 0; i < profilePhoto.length; i++) {
        if (
          profilePhoto[i].imageStatus === "APPROVED" &&
          profilePhoto[i].employeeImageContentType === "PROFILE"
        ) {
          setProfilePhotoItem(profilePhoto[i]);
        }
      }
    }

    return () => {};
  }, [profilePhoto]);

  const profileItems = [
    {
      id: 1,
      name: "Hesap Bilgilerim",
      link: "/profile/account-info",
      icon: "fas fa-user",
    },
    {
      id: 2,
      name: "Adres Bilgilerim",
      link: "/profile/address",
      icon: "fas fa-map-marker-alt",
    },
    {
      id: 3,
      name: "Mesajlarım",
      link: "/profile/messages",
      icon: "fas fa-user",
    },
    {
      id: 4,
      name: "Fotoğraflarım",
      link: "/profile/photos",
      icon: "fas fa-images",
    },
    {
      id: 5,
      name: "Puan Geçmişim",
      link: "/profile/scores",
      icon: "fas fa-chart-line",
    },
    {
      id: 6,
      name: "Sipariş Bilgilerim",
      link: "/profile/orders",
      icon: "fas fa-shopping-cart",
    },
    {
      id: 7,
      name: "Favorilerim",
      link: "/profile/favourites",
      icon: "fas fa-heart",
    },
    {
      id: 8,
      name: "Performans",
      link: "/profile/performance",
      icon: "fas fa-cogs",
    },
    {
      id: 9,
      name: "Dashboard",
      link: "/profile/dashboard",
      icon: "fas fa-chart-pie",
    },
  ];

  const handlePage = (page) => {
    switch (page) {
      case "account-info":
        return <AccountInfo />;
      case "scores":
        return <Scores />;
      case "orders":
        return <Orders />;
      case "address":
        return <Address />;
      case "photos":
        return <Photos />;
      case "messages":
        return <Messages />;
      case "favourites":
        return <Favourites />;
      case "performance":
        return <Performance />;
      case "dashboard":
        return <Dashboard />;
      default:
        return <AccountInfo />;
    }
  };

  return (
    <React.Fragment>
      <Header />
      <main>
        <section className="bg-blue py-5 mb-5">
          <div className="container">
            <div className="d-flex justify-content-start align-items-center text-decoration-none">

              {profilePhotoItem?.imageUrl ?
                <img
                  src={profilePhotoItem?.imageUrl}
                  className="bg-white shadow-sm rounded-circle d-flex justify-content-center align-items-center fw-bold"
                  style={{ width: "96px", height: "96px" }}
                  alt="user-profile"
                /> : 
                <div className="shadow-sm rounded-circle bg-gray d-flex flex-col justify-content-center align-items-center" style={{ height: 150, width: 150 }}>
                  <FontAwesomeIcon
                    icon={faUser}
                    className="text-gray-special"
                    style={{ width: 80, height: 80, color: '#fff' }}
                  />
                </div>
              }
              <div className="ms-4">
                <h2 className="mb-2 text-white">
                  {profileDetail?.employeeDTO?.name}{" "}
                  {profileDetail?.employeeDTO?.surname}
                </h2>
                <span className="text-white opacity-50">
                  {profileDetail?.score} Puan
                </span>
              </div>
            </div>
          </div>
        </section>
        <section className="mb-sm-10 mb-5">
          <div className="container">
            <div className="row gy-0">
              <div className="col-xl-3 col-lg-4">
                <div className="pe-lg-4 pe-0">
                  <div className="offcanvas offcanvas-start offcanvas-responsive-lg">
                    <div className="offcanvas-header">
                      <h5 className="offcanvas-title">Menü</h5>
                      <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="offcanvas-body shadow-lg p-4 rounded-3">
                      <ul
                        className="list-unstyled d-grid gap-4 mb-0 overflow-hidden"
                        data-bs-dismiss="offcanvas"
                      >
                        {profileItems.map((item) => (
                          <li key={item.id}>
                            <Link
                              to={item.link}
                              className="text-decoration-none text-nowrap"
                              style={{
                                color: selectedItem === item.link ? "#4CAF50" : "inherit",
                              }}
                              onClick={() => setSelectedItem(item.link)}
                            >
                              <i
                                className={item.icon + " me-3 text-center"}
                                style={{ width: "24px" }}
                              ></i>
                              {item.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <a
                    href=".offcanvas"
                    className="btn btn-blue-light text-white rounded-circle p-0 d-flex align-items-center justify-content-center shadow position-fixed bottom-0 end-0 mb-3 me-3"
                    data-bs-toggle="offcanvas"
                  >
                    <i className="fas fa-bars"></i>
                  </a>
                </div>
              </div>
              <div className="col-xl-9 col-lg-8">{handlePage(page)}</div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </React.Fragment>
  );
};

export default ProfileTest;
