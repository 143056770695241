/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userAction, academyAction } from "../../redux/modules";

// Header - Footer
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

// Styles
import "./styles.scss";

const AcademyDetail = () => {
  const { topicId } = useParams();
  const dispatch = useDispatch();
  const [filteredAcademy, setFilteredAcademy] = useState(null);

  const academyList = useSelector((store) => store.academy.academyList);

  useEffect(() => {
    // Get Academy Detail List
    dispatch(academyAction.getAcademyList());

    return () => { };
  }, [dispatch, topicId]);

  useEffect(() => {
    if (academyList?.length > 0 && topicId) {
      const filteredData = academyList.filter((item) => item.id === parseInt(topicId));
      setFilteredAcademy(filteredData);
    }

    return () => { };
  }, [academyList, topicId]);

  return (
    <>
      <Header />

      <main>
        <section className="bg-blue pt-5 pb-5">
          <div className="container">
            <div className="d-lg-flex d-none text-white small mb-5 opacity-50" data-title-background>
              <NavLink to="/" className="text-white text-decoration-none me-3">Anasayfa</NavLink>
              <span className="me-3">»</span>
              <NavLink to="/academy" className="text-white text-decoration-none me-3">Akademi</NavLink>
              <span className="me-3">»</span>
              {filteredAcademy?.length > 0 && filteredAcademy[0]?.videoCategoryName &&
                <span className="text-white text-decoration-none">
                  {filteredAcademy[0].videoCategoryName}
                </span>
              }
            </div>

            {filteredAcademy?.length > 0 ?
              <div className="row justify-content-center mb-5" data-title-background>
                <div className="col-xl-8 col-lg-10 text-center">
                  <h1 className="lh-base mb-4 text-white">{filteredAcademy.name}</h1>
                  <div className="opacity-100 small text-white">
                    {filteredAcademy[0]?.topicName &&
                      <span className="text-white">
                        {filteredAcademy[0].topicName}
                      </span>
                    }
                  </div>
                </div>
              </div>
              : (
                <p>Academy bulunamadı yada yükleniyor...</p>
              )}
          </div>
        </section>

        <section className="position-relative mb-sm-10 mb-5 mt-n5">
          {filteredAcademy ? (
            <div className="container">
              <div className="row justify-content-center mb-5">
                <div className="col-xl-10">
                  <div className="overflow-hidden rounded-3">
                    {filteredAcademy?.articleContentDTOList?.length > 0 ?
                      <img src={filteredAcademy?.articleContentDTOList[0].baseAddress} alt="" className="img-cover w-100" /> :
                      <div className="" style={{ background: '#ddd', height: '500px' }}>
                        <iframe
                          width="100%"
                          height="500"
                          src="https://www.youtube.com/embed/S2N_C4M02Ws?si=GkBj2V1bFJIdifA8"
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerPolicy="strict-origin-when-cross-origin" allowFullScreen
                        />
                      </div>
                    }
                  </div>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-xl-10 content">
                  {filteredAcademy?.length > 0 && filteredAcademy[0]?.description &&
                    <span className="text-black">
                      {filteredAcademy[0].description}
                    </span>
                  }
                </div>
              </div>

            </div>
          ) : (
            <p>Academy bulunamadı yada yükleniyor...</p>
          )}
        </section>
      </main>

      <Footer />
    </>
  );
};

export default AcademyDetail;
